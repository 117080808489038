<template>
  <div>
    <!-- 正在获取用户信息页面 -->
    <div
      v-if="headimgurl == null"
      class="blue-grey lighten-5 px-6 py-16 d-flex flex-column align-center justify-space-between unselect"
      style="width:100vw;height:100vh"
    >
      <div class="d-flex flex-column align-center mt-6">
        <v-sheet
          color="white"
          elevation="2"
          height="120"
          width="120"
          class="rounded-circle"
        >
          <v-img
            contain
            width="120"
            height="120"
            class="rounded-circle"
            src="../../../assets/avatar.png"
          ></v-img>
        </v-sheet>
        <div class="black--text text-h6 text-center mt-11">
          <div>获取用户信息中</div>
        </div>
      </div>
    </div>
    <!-- 用户信息获取成功，待登录页面 -->
    <transition name="fade" v-else>
      <div
        class="blue-grey lighten-5 px-6 py-12 d-flex flex-column align-center justify-space-between unselect"
        style="width:100vw;height:100vh"
      >
        <div class="d-flex flex-column align-center mt-6">
          <v-sheet
            color="white"
            elevation="2"
            height="120"
            width="120"
            class="rounded-circle"
          >
            <v-img
              contain
              width="120"
              height="120"
              class="rounded-circle"
              :src="headimgurl"
            ></v-img>
          </v-sheet>
          <div class="black--text text-h6 text-center mt-2">
            <div>{{ nickname }}</div>
            <div class="mt-9">
              <div>您即将以此身份登录</div>
              <div>{{ system_name }}</div>
            </div>
          </div>
        </div>

        <div class="text-body1 mt-12 d-flex flex-column align-center">
          <v-btn
            @click="doLogin"
            color="success"
            min-width="260"
            min-height="50"
            class="text-h6"
          >
            登录
          </v-btn>
          <div
            @click="cancelLogin"
            class="mt-8"
            style="color:#707B9A;font-weight:600"
          >
            取消登录
          </div>
        </div>
      </div>
    </transition>
    <Snackbar />
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import { api_request } from "@/util/network";
import { parseQuery } from "@/util/util";

export default {
  name: "WechatCallback",
  data() {
    return {
      wait: true,
      id: null,
      system_name: null,
      headimgurl: null,
      nickname: "",
      identity_token: null,
      session_id: null
    };
  },
  async created() {
    await this.filterId();
    await this.getUserInfo();
    await this.getSchoolName();
  },
  methods: {
    async getSchoolName() {
      return this.$http
        .get(`api/system_config`)
        .delegateTo(api_request)
        .then(({ system_name }) => {
          this.system_name = system_name ? system_name : "瑞智康诚WebEXP";
        })
        .catch(({ code, message }) => {
          throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    async filterId() {
      return this.$http
        .get(`api/settings/login`)
        .delegateTo(api_request)
        .then(res => {
          let { qrcode } = res;
          this.id = qrcode;
        })
        .catch(({ code, message }) => {
          throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    getUserInfo() {
      if (this.id) {
        let urlQuery = location.search.substr(1);
        let queryData = parseQuery(urlQuery);
        if (queryData) {
          let { code } = queryData;
          let session_id = localStorage.getItem("session_id");
          this.session_id = session_id;
          let credentials = {
            code,
            session_id
          };
          this.$http
            .post(`api/source/${this.id}/session_finish`, credentials)
            .delegateTo(api_request)
            .then(data => {
              let {
                identity: {
                  claims: { fullname, avatar_url }
                },
                identity_token
              } = data;
              this.headimgurl = avatar_url;
              this.nickname = fullname;
              this.identity_token = identity_token;
            })
            .catch(({ code, message }) => {
              let ua = window.navigator.userAgent.toLowerCase();
              this.throwError(ua, code, message);
            })
            .delegateTo(this.$snackbar.delegateError);
        }
      }
    },
    throwError(ua, code, message) {
      if (
        ua.match(/MicroMessenger/i) == "micromessenger" &&
        ua.match(/wxwork/i) == "wxwork"
      ) {
        throw `获取失败：${this.$t(
          "api.wxworkqrcode." + code
        )}, 额外信息: ${this.$t(
          "api." + typeof message === "string"
            ? message
            : JSON.stringify(message)
        )}`;
      } else if (ua.match(/micromessenger/i) == "micromessenger") {
        throw `获取失败：${this.$t(
          "api.wechatqrcode." + code
        )}, 额外信息: ${this.$t(
          "api." + typeof message === "string"
            ? message
            : JSON.stringify(message)
        )}`;
      }
    },
    doLogin() {
      if (this.session_id && this.identity_token) {
        let payload = {
          session_id: this.session_id,
          identity_token: this.identity_token
        };
        this.$http
          .post(`api/auth/bind_session`, payload)
          .delegateTo(api_request)
          .then(() => {
            window.WeixinJSBridge.invoke("closeWindow");
            return "登录成功";
          })
          .catch(({ code, message }) => {
            throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    cancelLogin() {
      window.WeixinJSBridge.invoke("closeWindow");
    }
  },
  components: {
    Snackbar
  }
};
</script>

<style lang="less" scoped>
.v-application .text-h6 {
  font-family: system-ui !important;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
</style>
